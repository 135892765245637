<template>
  <Card>
    <template #header>
      <div
        class="d-flex align-items-center justify-content-between"
        style="width: 100%"
      >
        Yeni Satıcı
      </div>
    </template>

    <div class="row">
      <!-- ünvan -->
      <div class="col-12">
        <b-form-group class="add-style">
          <label for="unvan">Ünvanı * </label>
          <input
            class="form-control"
            type="text"
            id="unvan"
            v-model="title"
            required
          />
        </b-form-group>
      </div>
      <!-- kısa ünvan -->
      <div class="col-12">
        <b-form-group class="add-style">
          <label for="kunvan">Kısa Ünvanı * </label>
          <input
            class="form-control"
            type="text"
            id="kunvan"
            v-model="short_title"
            required
          />
        </b-form-group>
      </div>
      <!-- sahibi -->
      <div class="col-12">
        <div class="form-group">
          <label for="saticiTip">Satıcı Tipi * </label>
          <select class="form-control" id="saticiTip" v-model="vendor_type">
            <option selected>Seçiniz...</option>
            <option value="1">Banka</option>
            <option value="2">Kurumsal</option>
            <option value="3">Bireysel</option>
          </select>
        </div>
        <b-form-checkbox
          name="check-button"
          switch
          class="mb-5"
          v-model="seller_status"
        >
          Satıcı Durumu
        </b-form-checkbox>
      </div>
    </div>
    <hr w-100 />

    <div class="row mt-5">
      <div class="col-12">
        <div class="card-sub-header mb-5">İletişim Bilgileri</div>
      </div>
      <!-- ünvan -->
      <div class="col-4">
        <b-form-group class="add-style">
          <label for="telNo2">Telefon 1 </label>
          <input
            class="form-control"
            type="phone"
            id="telNo2"
            v-model="phone_1"
            required
          />
        </b-form-group>
      </div>
      <!-- tel -->
      <div class="col-4">
        <b-form-group class="add-style">
          <label for="tel2">Telefon 2</label>
          <input
            class="form-control"
            type="phone"
            id="tel2"
            v-model="phone_2"
            required
          />
        </b-form-group>
      </div>
      <!-- faks -->
      <div class="col-4">
        <b-form-group class="add-style">
          <label for="faks">Faks</label>
          <input
            class="form-control"
            type="text"
            id="faks"
            v-model="fax"
            required
          />
        </b-form-group>
      </div>
      <!-- vergi d -->
      <div class="col-6">
        <b-form-group class="add-style">
          <label for="vergiD">Vergi Dairesi</label>
          <input
            class="form-control"
            type="text"
            id="vergiD"
            v-model="tax_administration"
            required
          />
        </b-form-group>
      </div>
      <!-- vergi no -->
      <div class="col-6">
        <b-form-group class="add-style">
          <label for="VergiN">Vergi Numarası</label>
          <input
            class="form-control"
            type="phone"
            id="VergiN"
            v-model="tax_number"
            required
          />
        </b-form-group>
      </div>
      <!-- hizmet bedeli -->
      <div class="col-6">
        <b-form-group class="add-style">
          <label for="HBHN">Hizmet Bedeli Hesap Numarası (IBAN)</label>
          <input
            class="form-control"
            type="phone"
            v-model="service_fee_account_number"
            id="HBHN"
            required
          />
        </b-form-group>
      </div>
      <!-- hesap no -->
      <div class="col-6">
        <b-form-group class="add-style">
          <label for="hniban">Hesap Numarası (IBAN)</label>
          <input
            class="form-control"
            v-model="account_number"
            type="phone"
            id="hniban"
            required
          />
        </b-form-group>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-12">
        <div class="card-sub-header mb-5">Şirket Ayarları</div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <label for="hizmetTip">Hizmet Bedeli Tipi * </label>
          <select
            class="form-control"
            id="hizmetTip"
            v-model="service_fee_type"
          >
            <option selected>Seçiniz...</option>
            <option value="1">Müşteri</option>
            <option value="2">Bankadan</option>
          </select>
        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <label for="ihalesuresi">İhale Süresi Tipi * </label>
          <select
            class="form-control"
            id="ihalesuresi"
            v-model="tender_term_type"
          >
            <option selected>Seçiniz...</option>
            <option value="N">Normal</option>
            <option value="I">İş Günü</option>
          </select>
        </div>
      </div>
      <div class="col-4">
        <b-form-group class="add-style">
          <label for="ihaleSue">İhale Süresi (Gün)</label>
          <input
            class="form-control"
            type="text"
            id="ihaleSure"
            v-model="tender_duration"
            required
          />
        </b-form-group>
      </div>
      <div class="col-4">
        <div class="form-group">
          <label for="temintip">Teminat Tipi</label>
          <select class="form-control" id="temintip" v-model="collateral_type">
            <option selected>Seçiniz...</option>
            <option value="1">Başlangıç Bedeli Üzerinden</option>
            <option value="2">İhale Sonuç Bedeli Üzerinden</option>
          </select>
        </div>
      </div>
    </div>
    <hr w-100 />
    <div class="col-12">
      <div class="card-sub-header mb-5">Teminat Oranı (%)</div>
    </div>
    <div
      class="row mt-5"
      v-for="(i, key) in coverage_rate_array"
      :id="'covarage-' + key"
      :key="'coverage' + i.id"
    >
      <div class="col-12 d-flex">
        <div class="col-1">
          <b-button variant="light" @click="deleteCoverage(key)">
            <i class="flaticon2-trash p-0"></i>
          </b-button>
        </div>
        <div class="col">
          <div class="form-group">
            <select
              class="form-control"
              @input="
                coverage_rate_array[key].coverage_rate_value =
                  $event.target.value
              "
            >
              <option value="0" selected>Tümü</option>
              <option value="1">0 TL - 500.000 TL</option>
              <option value="2">500.000 TL - 1.500.000 TL</option>
              <option value="3">1.500.000 TL - 5.000.000 TL</option>
              <option value="4">5.000.000 TL - 10.000.000 TL</option>
              <option value="5">10.000.000 TL +</option>
            </select>
          </div>
        </div>
        <div class="col">
          <b-form-group class="add-style">
            <input
              class="form-control"
              type="text"
              @input="
                coverage_rate_array[key].coverage_rate_percent =
                  $event.target.value
              "
            />
          </b-form-group>
        </div>
      </div>
    </div>
    <div class="col-12 mt-5">
      <button class="p-3 bg-light rounded-sm" @click="addCoverage">
        <b-icon icon="plus" aria-hidden="true"></b-icon> Ekle
      </button>
    </div>
    <hr w-100 />

    <div class="col-12 mt-5">
      <b-form-checkbox
        name="check-button"
        switch
        class="mb-5"
        style="height: auto !important"
        v-model="hbsht"
      >
        Hizmet bedelini sıralı hesaplayıp topla
      </b-form-checkbox>
    </div>
    <div class="col-12">
      <div class="card-sub-header mb-5">Hizmet Bedeli (%)</div>
    </div>

    <div
      class="row mt-5"
      v-for="(i, key) in hizmetBedeliArray"
      :key="'hizmet' + key"
    >
      <div class="col-12 d-flex">
        <div class="col-1">
          <b-button variant="light" @click="deleteHizmet(key)">
            <i class="flaticon2-trash p-0"></i>
          </b-button>
        </div>
        <div class="col">
          <div class="form-group">
            <select
              class="form-control"
              @input="
                hizmetBedeliArray[key].hizmetBedeliDegeri = $event.target.value
              "
            >
              <option value="0" selected="selected">Tümü</option>
              <option value="1">0 TL - 5.000.000 TL</option>
              <option value="2">5.000.000 TL - 10.000.000 TL</option>
              <option value="3">10.000.000 TL +</option>
            </select>
          </div>
        </div>
        <div class="col">
          <b-form-group class="add-style">
            <input
              class="form-control"
              type="text"
              @input="
                hizmetBedeliArray[key].hizmetBedeliYuzdesi = $event.target.value
              "
              required
            />
          </b-form-group>
        </div>
      </div>
    </div>
    <div class="col-12 mt-5">
      <button class="p-3 bg-light rounded-sm" @click="addHizmet">
        <b-icon icon="plus" aria-hidden="true"></b-icon> Ekle
      </button>
    </div>
    <hr w-100 />
    <div class="col-12">
      <div class="card-sub-header mb-5">Hizmet Bedeli Limit Üzeri (%)</div>
    </div>
    <div
      class="row mt-5"
      v-for="(i, key) in hizmetBedeliLimitArray"
      :key="'hizmetlimit' + key"
    >
      <div class="col-12 d-flex">
        <div class="col-1">
          <b-button variant="light" @click="deleteHizmetBedel(key)">
            <i class="flaticon2-trash p-0"></i>
          </b-button>
        </div>
        <div class="col">
          <div class="form-group">
            <select
              class="form-control"
              @input="
                hizmetBedeliLimitArray[key].hizmetBedeliLimitDeger =
                  $event.target.value
              "
            >
              <option value="0" selected="selected">Yok</option>
              <option value="1">1.000.000 TL +</option>
              <option value="2">3.000.000 TL +</option>
              <option value="3">5.000.000 TL +</option>
              <option value="4">8.000.000 TL +</option>
              <option value="5">10.000.000 TL +</option>
            </select>
          </div>
        </div>
        <div class="col">
          <b-form-group class="add-style">
            <input
              class="form-control"
              type="text"
              @input="
                hizmetBedeliLimitArray[key].hizmetBedeliLimitYuzde =
                  $event.target.value
              "
              required
            />
          </b-form-group>
        </div>
      </div>
    </div>
    <div class="col-12 mt-5">
      <button class="p-3 bg-light rounded-sm" @click="addHizmetBedeli">
        <b-icon icon="plus" aria-hidden="true"></b-icon> Ekle
      </button>
    </div>
    <hr w-100 />

    <div class="row mt-5">
      <div class="col-6">
        <div class="form-group">
          <label for="yuzde">Yüzde Artış Birimi * </label>
          <select
            class="form-control"
            id="exampleFormControlSelect1"
            v-model="minimum_ncrement_unit"
          >
            <option value="">Seçiniz...</option>
            <option value="%" selected="selected">Yüzde (%)</option>
            <option value="TL">Türk Lirası (TL)</option>
          </select>
        </div>
      </div>
      <div class="col-6">
        <b-form-group class="add-style">
          <label for="min">Minimum Artış Değeri *</label>
          <input
            class="form-control"
            type="text"
            id="min"
            v-model="minimum_increase_value"
            required
          />
        </b-form-group>
      </div>
    </div>
    <hr w-100 />

    <h4 class="mt-5 card-sub-header mb-5">Satıcı Logosu (Renkli)</h4>
    <b-form-group class="mb-0 mt-5">
      <b-form-file
        id="file1"
        class="rounded-sm"
        placeholder="Dosya seçiniz"
        drop-placeholder="Dosyayı buraya sürükleyebilirsiniz"
        v-model="dealer_logo"
      ></b-form-file>
    </b-form-group>

    <div class="row">
      <div class="col-12 ml-5 mt-5">
        <ul>
          <li>
            %%customer_adi_soyadi%% - Giriş yapmış kullanıcının adı soyadı
          </li>
          <li>%%customer_adres%% - Giriş yapmış kullanıcının adresi</li>
          <li>
            %%customer_telefon%% - Giriş yapmış kullanıcının telefon numarası
          </li>
          <li>%%customer_email%% - Giriş yapmış kullanıcının e-mail adresi</li>
          <li>
            %%suan_tarih%% - Sözleşme indir butonuna başıldığı anın tarihi
          </li>
          <li>%%suan_saat%% - Sözleşme indir butonuna başıldığı anın saati</li>
          <li>%%hizmet_bedeli%% - Sözleşmeye ait ihalenin hizmet bedeli</li>
          <li>
            %%hizmetbedeli_iban%% - Bankaya ait, hizmet bedelinin yatırılması
            gereken iban adresi.
          </li>
        </ul>
      </div>
    </div>
    <h4 class="mt-5 card-sub-header mb-4">Satış Sözleşmesi</h4>
    <b-form-group class="mb-0 mt-5">
      <b-form-file
        id="file2"
        class="rounded-sm mb-5"
        placeholder="Dosya seçiniz"
        drop-placeholder="Dosyayı buraya sürükleyebilirsiniz"
        v-model="sales_agreement"
      ></b-form-file>
      <a href="#" class="btn btn-success btn-sm">Dosya İndir</a>
    </b-form-group>

    <h4 class="mt-5 card-sub-header">Mesafeli Satış Sözleşmesi</h4>
    <b-form-group class="mb-0 mt-5">
      <b-form-file
        id="file3"
        class="rounded-sm mb-5"
        placeholder="Dosya seçiniz"
        drop-placeholder="Dosyayı buraya sürükleyebilirsiniz"
        v-model="distance_sales_agreement"
      ></b-form-file>
      <a href="#" class="btn btn-success btn-sm">Dosya İndir</a>
    </b-form-group>

    <div class="d-flex">
      <button
        @click="addSeller"
        class="btn btn-success rounded-sm p-3 mt-5 mx-auto"
      >
        Kaydet
      </button>
    </div>
  </Card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      title: "",
      short_title: "",
      vendor_type: "",
      seller_status: "",
      phone_1: "",
      phone_2: "",
      fax: "",
      tax_administration: "",
      tax_number: "",
      account_number: "",
      service_fee_account_number: "",
      service_fee_type: "",
      tender_term_type: "",
      tender_duration: "",
      collateral_type: "",
      coverage_rate: "",
      hbsht: "",
      service_fee: "",
      service_fee_over_limit: "",
      minimum_ncrement_unit: "",
      minimum_increase_value: "",
      dealer_logo: null,
      sales_agreement: null,
      distance_sales_agreement: null,
      coverage_rate_array: [
        {
          id: 1,
          coverage_rate_value: "0",
          coverage_rate_percent: "",
        },
      ],
      hizmetBedeliArray: [
        {
          id: 1,
          hizmetBedeliDegeri: "0",
          hizmetBedeliYuzdesi: "",
        },
      ],
      hizmetBedeliLimitArray: [
        {
          id: 1,
          hizmetBedeliLimitDeger: "0",
          hizmetBedeliLimitYuzde: "",
        },
      ],
    };
  },
  methods: {
    addCoverage() {
      const ids =
        this.coverage_rate_array[this.coverage_rate_array.length - 1].id;
      this.coverage_rate_array.push({
        id: ids + 1,
        coverage_rate: "0",
        coverage_rate_percent: "",
      });
    },
    addHizmet() {
      const ids = this.hizmetBedeliArray[this.hizmetBedeliArray.length - 1].id;
      this.hizmetBedeliArray.push({
        id: ids + 1,
        hizmetBedeliDegeri: "0",
        hizmetBedeliYuzdesi: "",
      });
    },
    addHizmetBedeli() {
      const ids =
        this.hizmetBedeliLimitArray[this.hizmetBedeliLimitArray.length - 1].id;

      this.hizmetBedeliLimitArray.push({
        id: ids + 1,
        hizmetBedeliLimitDeger: "0",
        hizmetBedeliLimitYuzde: "",
      });
    },
    deleteCoverage(i) {
      this.coverage_rate_array.splice(i, 1);
    },
    deleteHizmet(i) {
      this.hizmetBedeliArray.splice(i, 1);
    },
    deleteHizmetBedel(i) {
      this.hizmetBedeliLimitArray.splice(i, 1);
    },
    addSeller() {
      const data = {
        title: this.title,
        short_title: this.short_title,
        vendor_type: this.vendor_type,
        seller_status: this.seller_status,
        phone_1: this.phone_1,
        phone_2: this.phone_2,
        fax: this.fax,
        tax_administration: this.tax_administration,
        tax_number: this.tax_number,
        account_number: this.account_number,
        service_fee_account_number: this.service_fee_account_number,
        service_fee_type: this.service_fee_type,
        tender_term_type: this.tender_term_type,
        tender_duration: this.tender_duration,
        collateral_type: this.collateral_type,
        coverage_rate: this.coverage_rate,
        hbsht: this.hbsht,
        service_fee: this.service_fee,
        service_fee_over_limit: this.service_fee_over_limit,
        minimum_ncrement_unit: this.minimum_ncrement_unit,
        minimum_increase_value: this.minimum_increase_value,
        user_token: this.myUser,
      };
      const formData = new FormData();

      for (const key in data) {
        formData.append(key, data[key]);
      }
      formData.append("dealer_logo", this.dealer_logo);
      formData.append("sales_agreement", this.sales_agreement);
      formData.append(
        "distance_sales_agreement",
        this.distance_sales_agreement
      );
      this.coverage_rate_array.forEach((coverage, index) => {
        formData.append(
          `coverage_rate[${index}][coverage_rate_value]`,
          coverage.coverage_rate_value
        );
        formData.append(
          `coverage_rate[${index}][coverage_rate_percent]`,
          coverage.coverage_rate_percent
        );
      });
      this.hizmetBedeliArray.forEach((hizmet, index) => {
        formData.append(
          `service_fee[${index}][service_fee_value]`,
          hizmet.hizmetBedeliDegeri
        );
        formData.append(
          `service_fee[${index}][service_fee_percent]`,
          hizmet.hizmetBedeliYuzdesi
        );
      });
      this.hizmetBedeliLimitArray.forEach((hizmet, index) => {
        formData.append(
          `service_fee_over_limit[${index}][service_fee_over_limit_value]`,
          hizmet.hizmetBedeliLimitDeger
        );
        formData.append(
          `service_fee_over_limit[${index}][service_fee_over_limit_percent]`,
          hizmet.hizmetBedeliLimitYuzde
        );
      });
      this.$ApiService
        .post("admin/seller", formData)
        .then((response) => {
          if (response.data.status !== 400) {
            this.$generateNotification(
              this,
              "success",
              "Satıcı Başarıyla Kaydedildi"
            );
            //this.$router.push("/manage/bank");
          } else {
            this.$generateNotification(
              this,
              "error",
              "Satıcı kaydedilirken bir hata ile karşılaşıldı"
            );
          }
        })
        .catch((err) => {
          this.$generateNotification(
            this,
            "error",
            "Satıcı kaydedilirken bir hata ile karşılaşıldı"
          );
        });
      this.$ApiService
        .postFile("admin/seller/file", formData)
        .then((response) => {
          if (response.data.status !== 400) {
            this.$generateNotification(
              this,
              "success",
              "Satıcı Başarıyla Kaydedildi"
            );
          } else {
            this.$generateNotification(
              this,
              "error",
              "Satıcı kaydedilirken bir hata ile karşılaşıldı"
            );
          }
        })
        .catch((err) => {
          this.$generateNotification(
            this,
            "error",
            "Satıcı kaydedilirken bir hata ile karşılaşıldı"
          );
        });
    },
  },
  computed: {
    ...mapGetters(["myUser"]),
  },
};
</script>
<style lang="scss">
.custom-control-input[type="checkbox"] {
  height: auto !important;
  width: auto !important;
}
</style>
